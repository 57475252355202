import(/* webpackMode: "eager" */ "/Users/joostschuur/Code/Personal/snacklang/node_modules/.pnpm/@next+third-parties@15.2.2_next@15.2.2_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@_0088d3c5cb7cd7e86e4d50abda764352/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/Users/joostschuur/Code/Personal/snacklang/node_modules/.pnpm/@next+third-parties@15.2.2_next@15.2.2_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@_0088d3c5cb7cd7e86e4d50abda764352/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/Users/joostschuur/Code/Personal/snacklang/node_modules/.pnpm/@next+third-parties@15.2.2_next@15.2.2_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@_0088d3c5cb7cd7e86e4d50abda764352/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/Users/joostschuur/Code/Personal/snacklang/node_modules/.pnpm/next@15.2.2_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/Users/joostschuur/Code/Personal/snacklang/node_modules/.pnpm/next@15.2.2_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Geist\",\"arguments\":[{\"variable\":\"--font-geist-sans\",\"subsets\":[\"latin\"]}],\"variableName\":\"geistSans\"}");
;
import(/* webpackMode: "eager" */ "/Users/joostschuur/Code/Personal/snacklang/node_modules/.pnpm/next@15.2.2_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Geist_Mono\",\"arguments\":[{\"variable\":\"--font-geist-mono\",\"subsets\":[\"latin\"]}],\"variableName\":\"geistMono\"}");
;
import(/* webpackMode: "eager", webpackExports: ["NuqsAdapter"] */ "/Users/joostschuur/Code/Personal/snacklang/node_modules/.pnpm/nuqs@2.4.1_next@15.2.2_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0__react@19.0.0/node_modules/nuqs/dist/adapters/next/app.js");
;
import(/* webpackMode: "eager" */ "/Users/joostschuur/Code/Personal/snacklang/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/joostschuur/Code/Personal/snacklang/src/components/Providers.tsx");
